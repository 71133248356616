import React from 'react';
import {withRouter} from 'react-router-dom';
import TopicService from '../../services/topics.service.js';
import {__debug, remove_special_char, extract_verse_info, formatLink} from '../../utils.js';
import {Helmet} from 'react-helmet';
import {START_LOADING, STOP_LOADING} from "../../redux/actions/loader.js"
import {connect} from 'react-redux';
import TextToSpeech from '../../services/text-to-speech.js';
import {emitter} from '../common/function/event-emitter/event-emitter.jsx';
import {LinkHoverUnderline} from '../common/reusable/link-hover-underline/link-hover-underline.jsx';
import {AccountService} from "../../services/account.service.js";
import {replaceWithHighlight} from './../../utils.js';
import {BsStarFill} from "react-icons/bs";
import './verse-card.css';
import Button from 'react-bootstrap/Button';

class TopicDetail extends React.Component {
    constructor(props) {
        super(props)
        this.bibleLinkMenuRef = React.createRef()
        this.popupRefs = {}
        this.props.START_LOADING()
        const searchParams = new URLSearchParams(window.location.search)
        const lang = localStorage.getItem('selectedLanguage') || 'en';
        const keyword = searchParams.get('search_text')?.toUpperCase() || ''
        this.state = {
            verseList: [],
            verseName: '',
            currentSubject: '',
            currentCategory: '',
            currentTopic: '',
            audioContent: null,
            auDisplay: false,
            rawtext: '',
            subjectUrl: '',
            categoryUrl: '',
            topicUrl: '',
            timeOnPage: 0,
            keyword,
            bookmarkPopupOpen : {},
            new_folder :"",
            showFolderInput :{},
            bm_folders : {},
            selectedFolderIdInput : '',
            createBookmarkError : false,
            lang,
            verseList_data : []
        };
        this.retrieveTopicDetailByVerseName = this.retrieveTopicDetailByVerseName.bind(this);
        this.handleOnclickReadMore = this.handleOnclickReadMore.bind(this);
        this.handleOnclickReadIcon = this.handleOnclickReadIcon.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }

    async componentDidMount() {
        const isLogin = AccountService.userValue ? true : false;
        if (isLogin) {
            const voice_config = AccountService.userValue.data.voice_config;
            const lang = AccountService.userValue.data.language;
            sessionStorage.setItem('voiceConfig', voice_config)
            sessionStorage.setItem('selectedLanguage', lang)
        }
        emitter.on('voiceChange', voice_name => {
            this.reloadAudio(this.state.rawtext, voice_name)
        });
        emitter.on('languageChange', this.handleLanguageChange);

        let subjectPath = null;
        let categoryPath = null;
        let topicPath = null;
        let is_valid = false;
        let subjectUrl ='' , categoryUrl =''
        if (this.props.match && this.props.match.params) {
            subjectPath = this.props.match.params.subjectId;
            categoryPath = this.props.match.params.categoryId;
            topicPath = this.props.match.params.topicId;
            subjectUrl = `/bible/${subjectPath}`
            categoryUrl =`/bible/${subjectPath}/${categoryPath}`
            this.setState({
                topicUrl: `/bible/${subjectPath}/${categoryPath}/${topicPath}`
            })
        }
        if (subjectPath != null && categoryPath != null && topicPath != null) {
            let element_list = subjectPath.split("-");
            let subjectId = element_list[element_list.length - 1];
            let subject_prefix = element_list.slice(0, element_list.length - 1).join("-")
            element_list = categoryPath.split("-");
            let categoryId = element_list[element_list.length - 1];
            let category_prefix = element_list.slice(0, element_list.length - 1).join("-")
            element_list = topicPath.split("-");
            let topicId = element_list[element_list.length - 1];
            let topic_prefix = element_list.slice(0, element_list.length - 1).join("-")
            const response = await TopicService.getTopicById(topicId);
            let found_topic = response.data?.data;
            if (found_topic) {
                found_topic = found_topic[0];
                subjectUrl += `?category=${formatLink(found_topic["category"])}`
                categoryUrl += `?topic=${formatLink(found_topic['topic'])}`
                if ((subject_prefix === remove_special_char(found_topic["subject"])) && (category_prefix === remove_special_char(found_topic["category"])) && topic_prefix.includes(remove_special_char(found_topic["topic"]))) {
                    is_valid = true;
                    this.setState({
                        currentSubject: found_topic["subject"],
                        currentCategory: found_topic["category"],
                        currentTopic: found_topic['topic'],
                        subjectId: subjectId,
                        categoryId: categoryId,
                        topicId: topicId,
                        verseName: found_topic["verse_info"],
                    });
                    await this.retrieveTopicDetailByVerseName(found_topic["verse_info"]);
                }
            }
        }
        this.setState({subjectUrl,categoryUrl})
        if (!is_valid) {
            this.props.history.push({
                pathname: `/error`,
            });
        }
        this.timer = setInterval(() => {
            this.setState((prevState) => ({timeOnPage: prevState.timeOnPage + 5}));
        }, 5000);

        document.addEventListener("mousedown", this.handleClickOutside);

        this.props.STOP_LOADING();
    }

    async componentWillUnmount() {
        emitter.off('voiceChange');
        emitter.off('languageChange', this.handleLanguageChange);
        clearInterval(this.timer);
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.timeOnPage !== this.state.timeOnPage && this.state.timeOnPage >= 5) {
            await AccountService.addSearchHistory({
                subject: this.state.currentSubject,
                category: this.state.currentCategory,
                topic: this.state.currentTopic,
                url: this.state.topicUrl,
                verse_info: this.state.verseName
            })
            clearInterval(this.timer);
        }
    }

    handleClickOutside = (event) => {
        const bookmark_popup = document.querySelector(".bookmark-popup");
        if (bookmark_popup && !bookmark_popup.contains(event.target)) {
            this.setState({bookmarkPopupOpen : {}})
        }
    };

    handleLanguageChange(lang) {
        let verseList_data = Array.from(this.state.verseList_data)
        let verseList = this.createVerseList(verseList_data,lang)
        this.setState({
            lang,verseList
        });
    }

    async retrieveTopicDetailByVerseName(currentVerseInfo) {
        await TopicService.getTopicDetailByName(currentVerseInfo)
            .then((response) => {
                const verseList_data = response.data?.verse_info || []
                const lang = this.state.lang || 'en'
                let verseList = this.createVerseList(verseList_data,lang)
                this.setState({
                    verseList,
                    verseList_data
                });
            })
            .catch((err) => {
                __debug(err, 'e');
            });
    }

    createVerseList(verseList_data,lang) {
        let verseList = []
            Array.from(verseList_data).forEach((item) => {
                if (item["verse_string_valid"] && item["verse_found"]) {
                    let raw_text_word_list = item['verse_raw_text'][lang].split(' ');
                    let display_text = item['verse_raw_text'][lang];
                    let read_text = display_text;
                    if (raw_text_word_list.length > 50) {
                        read_text = raw_text_word_list.slice(0, 50).join(' ')
                        display_text = read_text + "...";
                    }
                    let new_item = {...item,display_text,read_text}
                    verseList.push(new_item)
                }
            })
        return verseList
    }

    handleOnclickReadMore = (verse_id_string) => {
        const extract_result = extract_verse_info(verse_id_string);
        if (extract_result) {
            this.props.history.push({
                pathname: `/chapter-overview/${encodeURI(extract_result["doc_chapter_info"])}#${encodeURI(extract_result["html_doc_id"])}`,
            });
        } else {
            this.props.history.push({
                pathname: `/chapter-overview/${encodeURI(verse_id_string)}`,
            });
        }
    };

    handleOnclickReadIcon = async (text) => {
        const lang = localStorage.getItem('selectedLanguage') || 'en';
        const voiceConfigText = sessionStorage.getItem('voiceConfig') || JSON.stringify({"en": "en-US-Standard-B", "es": "es-US-Studio-B"});
        const voiceConfig = JSON.parse(voiceConfigText)
        const voice = voiceConfig[lang]
        this.setState({
            auDisplay: true
        })
        if (this.state.voice !== voice) {
            this.reloadAudio(text, voice);
        } else {
            if (text !== this.state.rawtext) {
                this.reloadAudio(text, voice);
            } else {
                const audioElement = document.getElementById('myAudio');
                if (audioElement.paused) {
                    audioElement.play();
                } else {
                    audioElement.pause();
                }
            }
        }
    }

    reloadAudio = (text, voice) => {
        if (voice && text) {
            setTimeout(async () => {
                this.setState({
                    voice: voice
                })
                const audioContent = await TextToSpeech.makespeech(text, voice)
                this.setState({
                    audioContent: audioContent, auDisplay: true, rawtext: text,
                });
            }, 200)
        }
    }

    cancelAudio = () => {
        this.setState({
            auDisplay: false,
        })
    }

    handleOpenBookmark = async (id) => {
        const bookmarkPopupOpen = { [id]: true };
        const bm_folders_data = await AccountService.listBookmarkFolder();
        const bm_folders = bm_folders_data.data;

        this.setState(
            { bookmarkPopupOpen, bm_folders, selectedFolderIdInput: "", new_folder: "" },
            () => {
            // Ensure the specific popup is fully visible
            const popup = this.popupRefs[id];
            const container = this.bibleLinkMenuRef.current;

            if (popup && container) {
                const popupRect = popup.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();

                if (popupRect.bottom > containerRect.bottom) {
                // Scroll the container to bring the popup into view
                const scrollDiff = popupRect.bottom - containerRect.bottom;
                container.scrollTop += scrollDiff;
                } else if (popupRect.top < containerRect.top) {
                // If the popup is above the visible area, scroll up
                const scrollDiff = containerRect.top - popupRect.top;
                container.scrollTop -= scrollDiff;
                }
            }
            }
        );
    };



    toggleFolderInput = (id) => {
        const prev_showFolderInput= this.state.showFolderInput
        if (prev_showFolderInput[id]) {
            this.setState({showFolderInput : {}})
        } else {
            const showFolderInput = { [id]: true };
            this.setState({ showFolderInput }, () => {
            // Ensure the popup is fully visible after toggling
            const popup = this.popupRefs[id];
            const container = this.bibleLinkMenuRef.current;

            if (popup && container) {
                const popupRect = popup.getBoundingClientRect();
                const containerRect = container.getBoundingClientRect();

                if (popupRect.bottom > containerRect.bottom) {
                    // Scroll the container to bring the popup into view
                    const scrollDiff = popupRect.bottom - containerRect.bottom;
                    container.scrollTop += scrollDiff;
                } else if (popupRect.top < containerRect.top) {
                    // If the popup is above the visible area, scroll up
                    const scrollDiff = containerRect.top - popupRect.top;
                    container.scrollTop -= scrollDiff;
                }
            }
        });
        }  
    }

    createFolder = async () => {
        const folderName = this.state.new_folder
        if (folderName) {
            const res = await AccountService.createBookmarkFolder(folderName);
            if (res.status === 200) {
                const folder = res.data
                const bm_folders =  [...this.state.bm_folders,folder]
                const selectedFolderIdInput = folder.id.toString()
                this.setState({
                    bm_folders,
                    showFolderInput : false,
                    new_folder : '',
                    selectedFolderIdInput
                })
                emitter.emit('createFolder',folder);
            } 
        }
        
    }

    createBookmark = async (bookmarkNameInput) => {
        const chosen_folderId = this.state.selectedFolderIdInput.toString()
        const bm_folders = this.state.bm_folders

        let folderId= ""
        if (chosen_folderId) { 
            folderId = chosen_folderId
        } else {
            const result = bm_folders.find(folder => folder.isDefault === 1 && folder.folderName === "Uncategorized");
            folderId = result ? (result.id).toString() : "";
        }
        const param = {
            name: bookmarkNameInput,
            url: this.getCurrentWindowUrl(),
            folderId
        };
        const res = await AccountService.addBookmark(param);
        if (res.status ===200) { 
            const newBookmark = res.data
            const bookmarkPopupOpen = {}
            this.setState({bookmarkPopupOpen,selectedFolderIdInput : ''})
            emitter.emit('createBookmark',newBookmark);
        }
    }

    getCurrentWindowUrl =() => {
        let url = window.location.href.trim("");
        return url.replace(/\/+$/, '');
    }
  

    render() {
        const {
            verseList, 
            currentSubject, 
            currentCategory, 
            currentTopic, 
            audioContent, 
            subjectUrl, 
            categoryUrl, 
            keyword, 
            bookmarkPopupOpen,
            new_folder,
            showFolderInput,
            bm_folders,
            selectedFolderIdInput
        } = this.state;
        const auDisplay = this.state.auDisplay;
        return (
        <div className='topic-detail-page'>
            <Helmet>
                <title> View Details for a Topic | ScriptureCast®</title>
            </Helmet>
            <div className='subject-list-content bible-content'>
                <div className='left-section'>
                    {keyword && <div>Keyword: <strong>{keyword}</strong></div>} 
                    <div className='left-item'>
                        <div className="sc-title">Subject</div>
                        <div className="sc-content">
                            <LinkHoverUnderline
                                url={subjectUrl}
                                linkText={currentSubject}
                                fontSize='font-size-medium'
                            />
                        </div>
                    </div>
                    <div className='left-item'>
                        <div className="sc-title">Category</div>
                        <div className="sc-content">
                            <LinkHoverUnderline
                                url={categoryUrl}
                                linkText={currentCategory}
                                fontSize='font-size-medium'
                            />
                        </div>
                        <div className='left-item'>
                            <div className="sc-title">Topic:</div>
                            <div className="sc-content"> <LinkHoverUnderline
                                url={categoryUrl}
                                linkText={currentTopic}
                                fontSize='font-size-medium'
                            /></div>
                        </div>
                       
                    </div>
                </div>
                <div className='right-section'>
                 <div className="topic-audio">
                    {auDisplay && 
                        <div>
                            <audio id="myAudio" src={'data:audio/mp3;base64,' + audioContent} controls autoPlay/>
                        </div>
                    }
                </div>
                    <div className="bible-link-menu" ref={this.bibleLinkMenuRef}>
                        {verseList && verseList.map((item, index) => (  
                        <div key={index}>
                            {item['display_text'] && 
                              <div className="verse-card verse-font">
                <div className="text-center">– {item['verse_id_string']} –</div>
                <div>{replaceWithHighlight(item['display_text'], keyword)}</div>
                <div className="readmore-box">
                    <div className="button" onClick={() => {
                                            this.handleOnclickReadMore(item['verse_id_string'])
                                        }}>
                        Read More of {item['doc_name']} {item['chapter_number']}
                    </div>

                    <div className="volume-up button" onClick={() => {
                                            this.handleOnclickReadIcon(item['read_text'])
                                        }}>
                        <i className="fa fa-volume-up"></i>
                    </div>
                    <div className='position-relative bookmark'>
                        <div className="button"  onClick={() => {this.handleOpenBookmark(item['chapter_id'])}}><BsStarFill/></div>
                        {bookmarkPopupOpen[item['chapter_id']] && 
                        <div className ='bookmark-popup' ref={(el) => (this.popupRefs[item["chapter_id"]] = el)}>
                            <p className='font-bold'>Create Bookmark</p>
                            <p>{item['verse_id_string']}</p>
                            <select value={selectedFolderIdInput} onChange={(event)=> this.setState({selectedFolderIdInput: event.target.value })} className='form-select-sm mb-1'>
                                <option key="-1" value="" disabled>Select a folder</option>
                                {Array.from(bm_folders).length>0 && Array.from(bm_folders).map((item, index) => (
                                    <option key={index} value={item["id"]}>{item["folderName"]}</option>
                                ))}
                            </select>
                           
                            
                            <div className='d-flex gap-2 mb-1'>
                                <Button variant="dark" className="bookmark-button" onClick={()=>this.createBookmark(item['verse_id_string'])}>Create Bookmark</Button>
                                <Button variant="dark" className="bookmark-button" onClick={()=>this.toggleFolderInput(item['chapter_id'])}>Create Folder</Button>
                            </div>
                             {showFolderInput[item['chapter_id']] && 
                                <div className='create-folder-div'>
                                    <hr/>
                                    <div className='d-flex gap-2'>
                                        <div>
                                            <input
                                            type='text'
                                            onClick={(e) => e.target.select()}
                                            value={new_folder}
                                            id='folder-name'
                                            className="form-control-sm"
                                            placeholder='Bookmark Folder'
                                            onChange={(e) => this.setState({
                                                new_folder: e.currentTarget.value
                                            })}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    this.createFolder()
                                                }
                                            }
                                            }></input>
                                        </div>
                                        <div>
                                            <Button variant="dark" className="bookmark-button" onClick={()=>this.createFolder()}>OK</Button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        }
                    </div>
                    
                </div>
            </div>
                                  
                                    }
                            </div>)
                        )}
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default withRouter(connect(null, {START_LOADING, STOP_LOADING})(TopicDetail));