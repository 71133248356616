import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter
} from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Helmet} from 'react-helmet';
import {connect} from 'react-redux';
import {START_LOADING, STOP_LOADING, SET_LOGIN_STATE} from './redux/actions/loader';
import {AccountService} from './services/account.service';
import {PrivateRoute} from './_helper/PrivateRoute';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TimeLimit from "./components/common/function/time-limit/time-limit"
import Popup from 'reactjs-popup';
import {Spinner} from './components/common/reusable/spinner/spinner';
import DonationError from './components/page-other/donation/donation-error';
import DonateSuccess from './components/page-other/donation/donate-success';
import DonationPage from './components/page-other/donation/donation-page'
import {UserMenu} from './components/common/fixed/user-menu/user-menu';
import Homepage from './components/page-core/homepage/homepage';
import AdvancedStudies from './components/page-core/homepage/advanced-studies';
import SubjectList from './components/page-bible/subject-list';
import CategoriesList from './components/page-bible/categories-list';
import CategoryBySubjectList from './components/page-bible/list-category-by-subject';
import TopicDetail from './components/page-bible/topic-detail';
import TopicByCategory from './components/page-bible/select-topic-by-category';
import ContactUs from './components/page-core/contact-us/contact-us';
import HowToUse from './components/page-other/how-to-use/how-to-use';
import HowToUseAdvanced from './components/page-other/how-to-use/how-to-use-advanced';
import HowToUseBookmarks from './components/page-other/how-to-use/how-to-use-bookmarks.jsx';
import HowToUseBasic from './components/page-other/how-to-use/how-to-use-basic';
import PrivacyPolicy from './components/page-core/privacy-policy/privacy-policy';
import TermOfUse from './components/page-core/term-of-use/term-of-use';
import SpiritualConsultation from './components/page-core/spiritual-consultation/spiritual-consultation';
import SpiritualGiftsAssessmentCheckoutPage
    from './components/page-other/spiritual-gift-assessment/checkout/spiritual-gifts-assessment-checkout-page';
import SpiritualGiftsAssessmentError
    from './components/page-other/spiritual-gift-assessment/error/spiritual-gifts-assessment-error';
import SpiritualGiftsAssessmentForm
    from './components/page-other/spiritual-gift-assessment/form/spiritual-gifts-assessment-form';
import SpiritualGiftsAssessmentSuccess
    from './components/page-other/spiritual-gift-assessment/success/spiritual-gifts-assessment-success';
import AboutUsSummary from './components/page-core/about-us/about-us-summary/about-us-summary';
import AboutUsIntroduction from './components/page-core/about-us/about-us-introduction/about-us-introduction';
import AssistantOnJourney from './components/page-other/assistant-on-journey/assistant-on-journey';
import Podcasts from './components/page-other/podcasts/podcasts';
import SearchResult from './components/page-bible/search-result';
import ErrorPage from './components/page-core/error-page/error-page';
import AppSearch from './components/page-other/search-box/AppSearch';
import ChapterOverview from './components/page-bible/chapter-overview-of-verse/chapter-overview-of-verse';
import NavHeader from './components/common/fixed/header/header'
import HelpMenu from './components/common/fixed/help-menu/help-menu';
import LanguageSelection from './components/common/fixed/language_selection/language_selection';

// import DonationButton from './components/common/fixed/donation-button/donation-button'
import ManageDonation from './components/page-other/donation/manage-donation';
import VerifyEmail from './components/account/verify-email-page/verify-email-page'
import ForgotPassword from './components/account/forgot-password-page/forgot-password-page';
import ResetPassword from './components/account/reset-password-page/reset-password-page'
import Login from './components/account/login-page/login-page'
import Register from './components/account/register-page/register-page'
import ChangePassword from './components/account/change-password-page/change-password-page'
import LogoutPage from './components/page-core/logout/logout-page';
import Footer from './components/common/fixed/footer/footer';
import ChapterService from './services/chapters.service';
import { emitter } from './components/common/function/event-emitter/event-emitter';
import { mouseflow } from 'react-mouseflow';
// import SearchResultVerse from './components/page-bible/search-result-verse';


const TITLE = 'Welcome to ScriptureCast®!';


class App extends React.Component {
    constructor(props) {
        super(props);
        this.props.START_LOADING();
        this.state = {
            'openSearch': false,
        }
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.closeSearch = this.closeSearch.bind(this);
    }

    async componentDidMount() {
        this.props.STOP_LOADING();
        const user = AccountService.userValue;
        if (user) {
            this.props.SET_LOGIN_STATE();
            this.getBookmark();
        }
        await this.getData()
        window.addEventListener('keydown', this.handleKeyDown);
        emitter.on('openPopup', status => this.setState({openSearch :status}))
        // Initialize Mouseflow
        mouseflow.initialize('68cec262-fe12-4cfa-9761-dc03a1d8fc9c');
    }

    componentWillUnmount() {
        emitter.off('openPopup');
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown(event) {
        // Check if Ctrl + S is pressed
        if (event.ctrlKey && event.key === 's') {
            // Prevent the default behavior of saving the page
            event.preventDefault();
            // Open the popup
            const appSearch = document.getElementById("app-search")
            if (!appSearch) {
                this.setState({
                    'openSearch': true,
                });
            }
        }
    };

    async getData() {
        const all_chapters = await ChapterService.getAllChapters()
        this.setState({
            all_chapters,
        })
    }

    closeSearch() {
        this.setState({
                'openSearch': false,
            }
        );
    }

    async getBookmark() {
        const list = await AccountService.getBookmark();
        sessionStorage.setItem('bookmarks', JSON.stringify(list.data));
    }

    render() {
        const {isLoading} = this.props;
        const {all_chapters} = this.state

        return (
            <>
                {isLoading && <Spinner/>}
                <TimeLimit></TimeLimit>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <div
                    className="app"
                >
                    <ToastContainer/>

                    <Router>
                        <div className='app-flex font-size-medium'>
                            <NavHeader/>
                            <div className='nav-buttons'>
                                <div>
                                    <LanguageSelection/>
                                    <UserMenu/>
                                    <HelpMenu/>
                                </div>
                            </div>
                            <div className="app-content">
                                <Switch>
                                    <Route exact path="/" component={Homepage}></Route>
                                    <PrivateRoute
                                        exact
                                        path="/subject-list"
                                        component={SubjectList}
                                    ></PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/category-list"
                                        component={CategoriesList}
                                    ></PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/bible/:subjectId"
                                        component={CategoryBySubjectList}
                                    ></PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/bible/:subjectId/:categoryId"
                                        component={TopicByCategory}
                                    ></PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/bible/:subjectId/:categoryId/:topicId"
                                        component={TopicDetail}
                                    ></PrivateRoute>
                                    <PrivateRoute
                                        exact
                                        path="/chapter-overview/:verseId"
                                        allChapters={all_chapters}
                                        component={ChapterOverview}
                                    ></PrivateRoute>
                                    <Route
                                        exact
                                        path="/advanced-studies"
                                        component={AdvancedStudies}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/about/contact"
                                        component={ContactUs}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/about/how-to-use"
                                        component={HowToUse}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/about/how-to-use-basic"
                                        component={HowToUseBasic}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/about/how-to-use-advanced"
                                        component={HowToUseAdvanced}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/about/how-to-use-bookmarks"
                                        component={HowToUseBookmarks}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/account/register"
                                        component={Register}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/account/change-password"
                                        component={ChangePassword}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/about-us/summary"
                                        component={AboutUsSummary}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/term-of-use"
                                        component={TermOfUse}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/donate"
                                        component={DonationPage}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/donation/payment/success"
                                        component={DonateSuccess}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/privacy-policy"
                                        component={PrivacyPolicy}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/donation/payment/canceled"
                                        component={DonationError}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/about-us/introduce-scripturecast"
                                        component={AboutUsIntroduction}
                                    ></Route>

                                    <Route
                                        exact
                                        path="/about-us/spiritual-consultation"
                                        component={SpiritualConsultation}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/podcasts"
                                        component={Podcasts}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/assistant-on-journey"
                                        component={AssistantOnJourney}
                                    ></Route>
                                    <Route
                                        path="/account/verify-email"
                                        component={VerifyEmail}
                                    ></Route>

                                    <Route
                                        exact
                                        path="/account/forgot-password"
                                        component={ForgotPassword}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/account/reset-password"
                                        component={ResetPassword}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/donate/subscription"
                                        component={ManageDonation}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/spiritual-gifts-assessment/questionnaire"
                                        component={SpiritualGiftsAssessmentForm}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/spiritual-gifts-assessment/introduction"
                                        component={SpiritualGiftsAssessmentCheckoutPage}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/spiritual-gifts-assessment/payment/canceled"
                                        component={SpiritualGiftsAssessmentError}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/spiritual-gifts-assessment/payment/success"
                                        component={SpiritualGiftsAssessmentSuccess}
                                    ></Route>
                                    <Route
                                        exact
                                        path="/search-result"
                                        component={SearchResult}
                                    ></Route>
                                    {/* <Route
                                        exact
                                        path="/search-result-by-verse-name"
                                        component={SearchResultVerse}
                                    ></Route> */}
                                    <Route
                                        exact
                                        path="/exit"
                                        component={LogoutPage}
                                    ></Route>
                                    <Route exact path="/account/login" component={Login}></Route>
                                    <Route exact path="*" component={ErrorPage}></Route>
                                </Switch>
                            </div>
                        </div>
                        <Footer/>

                        <Popup open={this.state.openSearch} onClose={this.closeSearch}>
                            {(close) => (
                                <div>
                                <span className="search-close" onClick={close}>
                                    &times;
                                </span>
                                    <AppSearch allChapters={all_chapters}/>
                                </div>
                            )}
                        </Popup>

                    </Router>
                </div>
            </>
        );
    }
}

// Connect state of reducer to this props
const mapStateToProps = (state) => {
    return {
        isLoading: state.isLoading,
    };
};

const mapDispatchToProps = {
    START_LOADING,
    STOP_LOADING,
    SET_LOGIN_STATE
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
