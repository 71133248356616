import doc_prefix_mapping from "./config/doc_prefix_mapping.json";

export const allowedLanguages = ['en', 'es'];

const LIST_SUPPORT_DOCS_NAME = [
    "genesis",
    "exodus",
    "leviticus",
    "numbers",
    "deuteronomy",
    "joshua",
    "judges",
    "ruth",
    "1samuel",
    "2samuel",
    "1kings",
    "2kings",
    "1chronicles",
    "2chronicles",
    "ezra",
    "esther",
    "job",
    "psalms",
    "proverbs",
    "ecclesiastes",
    "song of songs",
    "isaiah",
    "jeremiah",
    "lamentations",
    "ezekiel",
    "hosea",
    "joel",
    "amos",
    "obadiah",
    "jonah",
    "micah",
    "nahum",
    "habakkuk",
    "zephaniah",
    "haggai",
    "zechariah",
    "malachi",
    "matthew",
    "mark",
    "luke",
    "john",
    "acts",
    "romans",
    "1corinthians",
    "2corinthians",
    "galatians",
    "ephesians",
    "philippians",
    "colossians",
    "1thessalonians",
    "2thessalonians",
    "1timothy",
    "2timothy",
    "titus",
    "philemon",
    "hebrews",
    "james",
    "1peter",
    "2peter",
    "1john",
    "2john",
    "3john",
    "jude",
    "revelation",
    "daniel",
    "nehemiah"
]


export function __debug(message, type) {
    if (!process.env.REACT_APP_PRODUCTION) {
        switch (type) {
            case 'e': // error
                console.error(message, 'background: red; color: #fff');
                break;
            case 't': // trace error
                console.trace(message, 'background: red; color: #fff');
                break;
            case 'p': // http response
                console.info(message, 'background: #222; color: #bada55');
                break;
            case 'w': // warning
                console.warn(message, 'background: #4f560f; color: #e6ff07');
                break;
            case 'gtm': // gtm events, special
                console.info(message, 'background: #03dbfc; color: #000');
                break;
            default:
                console.info(
                    message,
                    'background: #d9d9d9; color: #a82868; font-weight: bold;'
                );
        }
    }
}

export const parseQuery = (queryString) => {
    var pairs = queryString.split('&');
    return pairs;
}

export const paragraphToDivs = (paragraph) => {
    return paragraph.split('\n').map((item, index) => {
        return <div key={index}>{item}</div>;
    });
};


export const escapedNewLineToLineBreakTag = (string) => {
    return string.split('\n').map((item, index) => {
        return (index === 0) ? item : [<br key={index}/>, item]
    })
}

export const replaceWithHighlight = (string, keyword) => {
   if (!keyword) return string;
    const regex = new RegExp(`\\b(${keyword})\\b`, 'gi');
    return string.split(regex).map((item, index) => {
        return <span className={item.toLowerCase() === keyword.toLowerCase() ? 'highlight' : ''}
                     key={index}>{escapedNewLineToLineBreakTag(item)}</span>;
    });
};

export const replaceWithHighlightIncluded = (string, keyword) => {
    if (!keyword) return string;
    const regex = new RegExp(`(${keyword})`, 'gi');
    return string.split(regex).map((item, index) => {
        return <span className={item.toLowerCase() === keyword.toLowerCase() ? 'highlight' : ''}
                     key={index}>{escapedNewLineToLineBreakTag(item)}</span>;
    });
};


export const remove_special_char = (string) => {
    return string.toLowerCase().replace(/[^a-z\d_\-\s]/g, "").replace(/\s/g, '-').replace(/--+/g, '-').replace(/^-+/, "").replace(/-+$/, "");
}

export const addParamsToURL = (self,params) => {
    const { history, location } = self.props;
    const searchParams = new URLSearchParams(location.search);
    Object.keys(params).forEach(key => {
        searchParams.set(key, params[key]);
    });
    history.push({
    pathname: location.pathname,
    search: searchParams.toString(),
    });
}

export const recognizeVerseName = (input) => {
    let lowercase_input = input.toLowerCase().trim();
    let is_doc_found = false;
    for (let index = 0; index < LIST_SUPPORT_DOCS_NAME.length; index++) {
        if (lowercase_input.startsWith(LIST_SUPPORT_DOCS_NAME[index])) {
            is_doc_found = true;
            break;
        }
    }
    if (is_doc_found) {
        const regex = /^[\dA-Za-z]+\s+\d{1,3}(?::[\s\d,-]+){0,1}$/
        const match = lowercase_input.match(regex);
        if (match) {
            return true
        } else {
            return false
        }
    }
    return false
};


export function wait(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function extract_verse_info(verse_id_string) {
    let first_verse_result = /^(.*) (\d+):(\d+)/.exec(verse_id_string);
    if (first_verse_result) {
        const doc_name = first_verse_result[1];
        const chapter_number = first_verse_result[2];
        const verse_index = first_verse_result[3];
        const html_prefix = doc_prefix_mapping["doc_name_tag_mapping"][doc_name.toLowerCase()]
        const html_doc_id = `${html_prefix}.${chapter_number}.${verse_index}`;
        const doc_chapter_info = `${doc_name} ${chapter_number}`
        return {doc_name, chapter_number, verse_index, doc_chapter_info, html_prefix, html_doc_id}
    }
    return null;
}


export function formatLink(raw_topic) {
    return raw_topic.replace(/[^\w\s-]/g, '').replace(/\s+/g, ' ').trim().replace(/\s/g, "-").toLowerCase()
}

export const isValidLanguage = (lang) => {
    return allowedLanguages.includes(lang);
};